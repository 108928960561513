@font-face {
  font-family: 'effrabold_italic';
  src: url('../../fonts/effra/effra_bold_italic-wf.woff2') format('woff2'),
       url('../../fonts/effra/effra_bold_italic-wf.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'effrabold';
  src: url('../../fonts/effra/effra_bold-wf.woff2') format('woff2'),
       url('../../fonts/effra/effra_bold-wf.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'effra_heavyitalic';
  src: url('../../fonts/effra/effra_heavy_italic-wf.woff2') format('woff2'),
       url('../../fonts/effra/effra_heavy_italic-wf.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'effra_heavyregular';
  src: url('../../fonts/effra/effra_heavy-wf.woff2') format('woff2'),
       url('../../fonts/effra/effra_heavy-wf.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'effraitalic';
  src: url('../../fonts/effra/effra_italic-wf.woff2') format('woff2'),
       url('../../fonts/effra/effra_italic-wf.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'effra_lightitalic';
  src: url('../../fonts/effra/effra_light_italic-wf.woff2') format('woff2'),
       url('../../fonts/effra/effra_light_italic-wf.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'effra_lightregular';
  src: url('../../fonts/effra/effra_light-wf.woff2') format('woff2'),
       url('../../fonts/effra/effra_light-wf.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'effra_mediumitalic';
  src: url('../../fonts/effra/effra_medium_italic-wf.woff2') format('woff2'),
       url('../../fonts/effra/effra_medium_italic-wf.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'effra_mediumregular';
  src: url('../../fonts/effra/effra_medium-wf.woff2') format('woff2'),
       url('../../fonts/effra/effra_medium-wf.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'effraregular';
  src: url('../../fonts/effra/effra_std_rg-wf.woff2') format('woff2'),
       url('../../fonts/effra/effra_std_rg-wf.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
