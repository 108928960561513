@import "./assets/styles/utilities.scss";
@import "./assets/styles/resets.scss";

// Fonts
@import "./assets/styles/fonts/fatfrank.scss";
@import "./assets/styles/fonts/effra.scss";

html {
  background: black;
  color: rgba(255,255,255,0.55);
}

p {
  font-family: "effraregular";
  font-size: 1.1em;
  line-height: 1.4;
  margin-bottom: 1.1em;

  strong {
    color: white;
    font-family: "effra_mediumregular";
  }

  em {
    font-family: "effraitalic";
  }
}

h1, h2, h3 {
  font-family: "fatfrank";
  letter-spacing: 0;
}

h1 {
  line-height: 1.1;
  margin-bottom: 1rem;
}

h2, h3 {
  color: white;
}

h2 {
  font-family: "fatfrank";
  font-size: 2em;
  line-height: 1.4;
  margin-bottom: 0.6rem;
}

h3 {
  font-size: 1.4em;
}

body {
  font-family: "effraregular";
  font-size: 16px;

  // Primary Mobile - Tablet & Below
  @media screen and (max-width: 1024px){
    font-size: 15px;
  } // End Tablet & Below

  // Secondary Mobile - Phone & Below
  @media screen and (max-width: 680px){
    // font-size: 14px;
  } // End Phone & Below
} // End body

.wrap {
  @include better-box(flex);
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  max-width: 58em;
  width: 85%;
}

// Pre Open Price Hidden
// .pre-open {
//   .menu-item-price {
//     display: none;
//   }
// }